import React from "react"
import Layout from "../components/layout"
import PortfolioItems from "../components/PortfolioItems"
import styled from "styled-components"

const PortfolioLayoutWrapper = styled.div`
  position: relative;
  max-width: 100%;
  padding: 1rem 3rem;
`

const PortfolioTitle = styled.h1`
  font-family: "Poppins", sans-serif;
  color: #171717;
  font-size: 45px;
  font-weight: 300;
  letter-spacing: -0.4px;

  @media (max-width: 576px) {
    text-align: center;
  }
`

const PortfolioUnderContent = ({ pageContext }) => {
  return (
    <Layout>
      <PortfolioLayoutWrapper>
        <PortfolioTitle
          dangerouslySetInnerHTML={{ __html: pageContext.title }}
        />
        {/* <div dangerouslySetInnerHTML={{ __html: pageContext.content }} /> */}
        <PortfolioItems />
      </PortfolioLayoutWrapper>
    </Layout>
  )
}

export default PortfolioUnderContent
