import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import styled from "styled-components"

const PortfolioItemsWrapper = styled.div`
  /* Grid styles */
  display: grid;
  margin: 2.8rem 0 0 0;
  align-items: center;
  grid-template-columns: repeat(2, minmax(240px, 1fr));
  grid-gap: 48px;

  @media (max-width: 992px) {
    grid-template-columns: repeat(2, minmax(140px, 1fr));
    grid-gap: 40px;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(100px, 1fr));
    grid-gap: 15px;
  }

  @media (max-width: 576px) {
    grid-template-columns: repeat(1, minmax(40px, 1fr));
  }
`

const PortfolioItem = styled.div`
  background-color: transparent;
  text-align: left;
  border: none;
  border-radius: 0;
  padding: 0;
  margin: 0 0 2.5rem 0;
  /* -webkit-box-shadow: 3px 3px 20px 6px rgba(142, 134, 134, 0.16);
  box-shadow: 3px 3px 20px 6px rgba(142, 134, 134, 0.16); */
  transition: 0.2s;
`

const PortfolioImage = styled.img`
  position: relative;
  display: block;
  max-width: 100%;
  height: auto;
  background-size: cover;
  overflow: hidden;

  @media (max-width: 992px) {
    min-width: 100%;
  }

  @media (max-width: 768px) {
    min-width: 100%;
  }

  @media (max-width: 576px) {
    min-width: 100%;
  }
`

const PortfolioItemTextWrapper = styled.div`
  position: relative;
  border-radius: 25px 0 0 0;
  background-color: #fff;
  padding: 1rem 2.1rem 2rem 2.1rem;
  margin-top: -110px;
  margin-left: 15px;
  border-bottom: 9px solid #eaeaea;

  @media (max-width: 992px) {
    margin-left: 10px;
  }

  @media (max-width: 768px) {
    margin-left: 5px;
  }

  @media (max-width: 576px) {
    margin-top: -40px;
    margin-left: 0;
    border-radius: 0;
    text-align: center;
  }
`

const PortfolioItemTitle = styled.h2`
  font-family: "Poppins", sans-serif;
  color: #171717;
  margin-block-end: 10.26px;
  margin-block-start: 10.26px;
  font-size: 22px;
  font-weight: 600;

  @media (max-width: 576px) {
    text-align: center;
  }
`

const PortfolioItemText = styled.div`
  text-align: left;
  font-size: 14px;
  color: #757474;
  line-height: 25px;
  margin-block-end: 22px;

  @media (max-width: 576px) {
    text-align: center;
  }
`

const ReadMoreLink = styled(Link)`
  /* position: relative; */
  text-decoration: none;
  color: #171717;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.78rem;
  white-space: nowrap;
  border: 3px solid #171717;
  border-radius: 0.5rem;
  padding: 0.8rem 0.99rem 0.85rem;
  width: auto;

  &:hover {
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#41aa0a),
      to(#0797b9)
    );
    background: linear-gradient(to right, #41aa0a, #0797b9);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    text-shadow: none;
    border-color: #0797b9;
  }
`

const PortfolioItems = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          allWordpressWpPortfolio {
            edges {
              node {
                id
                title
                slug
                excerpt
                content
                featured_media {
                  source_url
                  localFile {
                    childImageSharp {
                      # Specify the image processing specifications right in the query.
                      # Makes it trivial to update as your page's design changes.
                      fixed(quality: 60, width: 900) {
                        base64
                        width
                        height
                        src
                        srcSet
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={props => (
        <PortfolioItemsWrapper>
          {props.allWordpressWpPortfolio.edges.map(portfolioItem => (
            <PortfolioItem key={portfolioItem.id}>
              <PortfolioImage
                src={
                  portfolioItem.node.featured_media.localFile.childImageSharp
                    .fixed.src
                }
                alt="Image"
              />
              <PortfolioItemTextWrapper>
                <PortfolioItemTitle>
                  {portfolioItem.node.title}
                </PortfolioItemTitle>
                <PortfolioItemText
                  dangerouslySetInnerHTML={{
                    __html: portfolioItem.node.excerpt,
                  }}
                />
                <ReadMoreLink to={`/portfolio/${portfolioItem.node.slug}`}>
                  View project
                </ReadMoreLink>
              </PortfolioItemTextWrapper>
            </PortfolioItem>
          ))}
        </PortfolioItemsWrapper>
      )}
    />
  )
}

export default PortfolioItems
